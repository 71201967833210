<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-25 14:31:14
 * @LastEditors: Please set LastEditors
 * @Description: 充值
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
    <div class="sld_bankCard">
            <div class="bank_bottom_Form">
                <!-- 绑定银行卡 start -->
                <el-form :model="bankForm" label-width="120px" v-if="isBinding">
                    <el-form-item label="企业名称:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入企业名称" width="120px" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="证件类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.agencyClientGlobalType" placeholder="统一社会信用代码">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="证件号码:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.agencyClientGlobalId" placeholder="请输入证件号码" width="120px" />
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="银行类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item> -->
                    <el-form-item label="银行名称:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankName" placeholder="请输入银行卡号" @focus="inputFocus" @blur="inputBlur"
                                @input="inputName" ref="inputRef" />
                            <div class="search">
                                <el-scrollbar max-height="150px" class="search_dialog" v-if="isFocus">
                                    <!-- <ul class="search_dialog"> -->
                                    <div class="scrollbar-demo-item" v-for="item in bankList" :key="item.bankCode"
                                        @mousedown="selectName(item)">
                                        {{ item.bankName }}</div>
                                    <!-- </ul> -->
                                </el-scrollbar>
                            </div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="开户银行所在省:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="nodeItem" placeholder="港澳回乡证" v-if="!nodeList.length"></el-select>
                            <el-select v-model="nodeItem" placeholder="港澳回乡证" v-else>
                                <el-option v-for="item in nodeList" :key="item.nodeCode" :label="item.nodeName"
                                    :value="item.nodeCode" />

                                <!-- <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" /> -->
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="开户银行所在市:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="cityItem" placeholder="港澳回乡证" v-if="!cityList.length"></el-select>
                            <el-select v-model="cityItem" placeholder="港澳回乡证" v-else>
                                <el-option v-for="item in cityList" :key="item.cityCode" :label="item.cityName"
                                    :value="item.cityCode" />

                                <!-- <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" /> -->
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="开户行:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="开户行:"> -->
                    <el-form-item label="开户行:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="cityBankItem" placeholder="请选择开户行" @focus="inputFocus('cityBank')"
                                @blur="inputBlur('cityBank')" @input="inputName('cityBank')" ref="inputRef" />
                            <div class="search">
                                <el-scrollbar max-height="150px" class="search_dialog" v-if="iscityBankFocus">
                                    <!-- <ul class="search_dialog"> -->
                                    <div class="scrollbar-demo-item" v-for="item in cityBankList"
                                        :key="item.bankBnkcode" @mousedown="selectBank(item)">
                                        {{ item.bankLname }}</div>
                                    <!-- </ul> -->
                                </el-scrollbar>
                            </div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="银行类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.bankType" placeholder="港澳回乡证">
                                <el-option label="本行" value="1" />
                                <el-option label="外行" value="2" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="银行卡号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.bankCardNo" placeholder="请输入银行卡号" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.mobile" placeholder="请输入手机号" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="法人姓名:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.enterpriseLegalName" placeholder="请输入法人姓名" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="法人证件类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.reprGlobalType" placeholder="港澳回乡证">
                                <el-option label="身份证" value="1" />
                                <el-option label="港澳回乡证" value="3" />
                                <el-option label="台胞证" value="5" />
                                <el-option label="中国护照" value="4" />
                                <el-option label="外国护照" value="19" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="法人证件号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.reprGlobalId" placeholder="请输入法人证件号" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="经办人姓名">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.agencyClientName" placeholder="请输入经办人姓名" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="经办人证件类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.agencyClientGlobalType" placeholder="港澳回乡证" width="100%">
                                <el-option label="身份证" value="1" />
                                <el-option label="港澳回乡证" value="3" />
                                <el-option label="台胞证" value="5" />
                                <el-option label="中国护照" value="4" />
                                <el-option label="外国护照" value="19" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="开户银行名称:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入开户银行" />
                        </el-col>
                    </el-form-item> -->
                    <el-form-item label="经办人证件号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.agencyClientGlobalId" placeholder="请输入经办人证件号" />
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="手机号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入手机号" />
                        </el-col>
                    </el-form-item> -->
                    <div class="next">
                        <div @click="subBank">下一步</div>

                    </div>
                </el-form>
                <!-- 绑定银行卡 end -->
                <!-- 打款验证 start -->
                <el-form :model="bankForm" label-width="120px" v-else>
                    <el-form-item label="验证码:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入验证码" width="120px" />
                            <div class="timeOut">获取验证码</div>
                            <!-- <div class="timeOut">120s后获取</div> -->
                        </el-col>
                    </el-form-item>
                    <el-form-item label="打款金额:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入打款金额" width="120px" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="银行卡号">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入银行卡号" />
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item>
                        <div>打款银行： 上海银行</div>
                    </el-form-item>
                    <el-form-item>
                        <div>银行账户： 1111111</div>
                    </el-form-item> -->
                    <div class="next">
                        <div @click="nextTip">确认绑定</div>
                    </div>
                </el-form>
                <!-- 打款验证 end -->
            </div>
        </div>
</template>
  
<script>
import { useRouter } from 'vue-router';
import { ref, getCurrentInstance, computed, watch } from 'vue';
import { ElMessage, ElPagination, ElMessageBox, ElOption, ElSelect } from "element-plus";
export default {
    name: "UnBind",
    components: {
    },
    setup() {
        const { proxy } = getCurrentInstance();
        // 银行表单
        const bankForm = ref({
            acctOpenBranchName: '', //开户行名称
            agencyClientGlobalId: '', //经办人证件号
            agencyClientGlobalType: '', //经办人证件类型
            agencyClientName: '', //经办人姓名
            bankCardNo: '', //银行卡号
            bankType: '', //银行类型 1本行 2外行
            enterpriseLegalName: '', //法人姓名
            mobile: '', //手机号
            reprGlobalId: '', //法人证件号
            reprGlobalType: '', //法人证件类型 1-身份证；3-港澳回乡证；5-台胞证;4-中国护照;19-外国护照
            name: '',
        })
        let isBinding = ref(true) //判断绑定页面显示隐藏
        const router = useRouter()
        const next = () => {
            isBinding.value = false
        }
        const onSubmit = () => {
            console.log('submit!')
        }
        // 打款验证
        // 获得省市列表
        const bankList = ref([])
        const bankName = ref('')
        let bankCode = ref(null)
        let nodeList = ref([])
        let nodeItem = ref('')

        const getNodeList = () => {
            proxy.$get('/v3/member/front/member/nodeList',
                bankName
            ).then(res => {
                if (res.state == 200) {
                    nodeList.value = res.data
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        let cityList = ref({})
        let cityItem = ref('')
        const getCityList = () => {
            let param = {}
            param.nodeCode = nodeItem.value
            proxy.$get('/v3/member/front/member/cityList',
                param
            ).then(res => {
                if (res.state == 200) {
                    cityList.value = res.data
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        let cityBankList = ref([])
        let cityBankItem = ref('')
        const getCityBankList = () => {
            let param = {}
            param.cityCode = cityItem.value
            param.bankNo = bankCode.value
            param.nodeCode = nodeItem.value
            proxy.$get('/v3/member/front/member/bankaList',
                param
            ).then(res => {
                if (res.state == 200) {
                    cityBankList.value = res.data
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        watch(cityItem, () => {
            console.log(cityItem)
            getCityBankList()

        })
        watch(nodeItem, () => {
            getCityList()

        })
        getNodeList()
        // 银行卡名称start
        // 获取银行列
        const getBankList = () => {
            proxy.$get('api/pay/common/queryCity').then(res => {
                if (res.state == 200) {
                    bankList.value = res.data
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        getBankList()
        // 防抖函数
        const inputName = (type) => {
            // 防抖
            if (type == 'cityBank') {
                let timer
                if (timer) {
                    clearTimeout(timer)
                    timer = null
                }
                timer = setTimeout(() => {
                    getCityBankList()
                    clearTimeout(timer)
                    timer = null
                }, 500)
            }
            let timer
            if (timer) {
                clearTimeout(timer)
                timer = null
            }
            timer = setTimeout(() => {
                console.log(1)
                proxy.$get('api/pay/common/queryCity', { bankName: bankName.value }).then(res => {
                    if (res.state == 200) {
                        bankList.value = res.data
                    } else {
                        ElMessage.warning(res.msg)
                    }
                })
                clearTimeout(timer)
                timer = null
            }, 500)
        }
        let inputRef = ref(null)
        console.log(inputRef)
        const selectName = (item) => {
            console.log(item)
            bankName.value = item.bankName
            bankCode.value = item.bankCode
            // bankForm.value.bankCode = item.bankCode
            isFocus.value = false
        }
        // 选择开户行
        let cityBankCode = ref('')
        const selectBank = (item) => {
            cityBankItem.value = item.bankLname
            cityBankCode.value = item.bankBnkcode
        }
        const isFocus = ref(false)
        const iscityBankFocus = ref(false)
        const inputFocus = (type) => {
            if (type == 'cityBank') {
                iscityBankFocus.value = true
            } else {
                isFocus.value = true
            }  
        }
        // 失去焦点
        const inputBlur = (type) => {
            
            if (type == 'cityBank') {
                iscityBankFocus.value = false
            } else {
                isFocus.value = false
            }
            
            getNodeList()
        }
        // 提交表单
        const subBank = () => {
            let param = {}
            param.acctOpenBranchName = cityBankCode.value
            param.agencyClientGlobalId = bankForm.value.agencyClientGlobalId
            param.agencyClientGlobalType = bankForm.value.agencyClientGlobalType
            param.agencyClientName = bankForm.value.agencyClientName
            param.bankCardNo = bankForm.value.bankCardNo
            param.bankType = bankForm.value.bankType
            param.enterpriseLegalName = bankForm.value.enterpriseLegalName
            param.mobile = bankForm.value.mobile
            param.reprGlobalId = bankForm.value.reprGlobalId
            param.reprGlobalType = bankForm.value.reprGlobalType
            proxy.$post('/v3/member/front/member/bindCheckCorp', JSON.stringify(param),"json").then(res => {
                    if (res.state == 200) {
                        ElMessage.success(res.msg)
                        console.log(res)
                        testPhoneNum.value = res.data
                    } else {
                        ElMessage.warning(res.msg)
                    }
                })
        }
        // 银行名称 end
        // 绑卡成功
        const nextTip = () => {
            // 判断是否可以充值
            ElMessageBox.confirm(
                '绑卡成功，请设置您的支付密码',
                '提示',
                {
                    confirmButtonText: '前往设置 >',
                    // cancelButtonText: '立即开户>',
                    confirmButtonClass: 'dilog_button',
                    showCancelButton: false,
                    // cancelButtonClass: 'classDilog',
                    // type: 'warning',
                    center: true,
                    // confirmButtonClass: '',
                }
            )
                .then(() => {
                    router.push('/member/openAccount')
                    return
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: 'Delete canceled',
                    })
                })
            // 判断是否可以充值end
        }
        return {
            isBinding,
            next,
            onSubmit,
            bankForm,
            nextTip,
            inputFocus,
            inputBlur,
            selectName,
            inputName,
            bankList,
            isFocus,
            bankName,
            nodeList, //省级列表
            nodeItem,
            cityItem,
            cityList,
            cityBankList,
            cityBankItem,
            iscityBankFocus,
            selectBank,
            subBank
        };
    }
};

</script>
  
<style lang="scss">
.dilog_button {
    background-color: #fff !important;
    color: #409eff !important;
    margin: 0 auto;
    border: none;

    &:hover {
        background-color: #fff;
        color: #409eff;
        border: none;
    }
}

.bank_bottom_Form {
    .el-cascader {
        width: 100%;
    }

    .el-input__suffix {
        top: -4px;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 3px;

        .el-select {
            width: 100%;
        }
    }
}
</style>
<style lang="scss" scoped>
.scrollbar-demo-item {
    height: 50px;
    text-align: center;
    border-radius: 4px;
    color: var(--el-color-primary);
    width: 100%;
}

.search {
    // position: relative;
    position: absolute;

    .search_dialog {
        // position: absolute;
        background-color: white;
        z-index: 99;
        min-width: 335.324px;
        max-height: 300px;
        color: var(--el-text-color-regular);
        border-radius: var(--el-border-radius-base);
        background: white;
        padding: 6px 0;
        box-shadow: var(--el-box-shadow-light);
        box-sizing: border-box;
        overflow: hidden;

        div {
            width: 100%;
            height: 34px;
            line-height: 34px;
            cursor: pointer;
            padding-left: 20px;

            &:hover {
                background-color: var(--el-background-color-base);

            }
        }
    }
}

.bank_bottom_Form {
    padding-left: 60px;
    position: relative;

    .timeOut {
        position: absolute;
        top: 5px;
        right: 350px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }

    .next {
        width: 70px;
        background-color: #f02727;
        height: 30px;
        border-radius: 5px;
        text-align: center;
        line-height: 30px;
        color: #fff;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 30px;
    }
}

.sld_bankCard {
    width: 1020px;
    float: left;
    margin-left: 10px;

    .unbinding {
        height: 40px;
        line-height: 40px;
        text-align: right;
        width: 307px;
        color: rgba(30, 119, 240, 1);
        cursor: pointer;
    }

    .sld_bankCard_con {
        background: #fff;
        height: 1050px;
        // padding-bottom: 50px;
        padding: 0 20px;

        .bank_top2 {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                &:nth-child(1) {
                    line-height: 70px;
                    color: #1C1C1C;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
    }
}

.bank_center {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    .bank_state {
        display: flex;
        align-items: center;
    }

    .line {
        width: 351px;
        border: 1px solid rgba(187, 187, 187, 1);
        margin: 0 15px;
    }

    .red_state {
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
    }

    .white_state {
        position: relative;
        width: 123px;
        height: 123px;
        line-height: 123px;
        // background-color: rgba(240, 39, 39, 1);
        // color: rgba(255, 255, 255, 1);
        // color: black;
        font-size: 20px;
        text-align: center;
        font-family: Roboto;
        border-radius: 50%;
        border: 1px solid rgba(187, 187, 187, 1);

        span {
            position: absolute;
            left: 50%;
            bottom: -50px;
            transform: translate(-50%, -50%);
            line-height: 20px;
            font-size: 14px;
            color: black;
            width: 100px;
        }
    }

}
</style>